import { create } from 'zustand';

interface RequestStoreState {
  operations: string[];
  incrementRequests: (o: string) => void;
  decrementRequests: (o: string) => void;
}

const useRequestStore = create<RequestStoreState>((set) => ({
  operations: [],

  incrementRequests: (o) =>
    set((state) => ({ operations: [...state.operations, o] })),

  decrementRequests: (o) =>
    set((state) => ({ operations: state.operations.filter((op) => op !== o) })),
}));

export default useRequestStore;
