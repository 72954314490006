import { UserRoles } from './user';

export const MI_FACTOR = 0.621371;

export enum DateFormats {
  Online = 'YYYY.MM.DD HH:mm',
}

export const RolesWithSockets = [
  UserRoles.ADMIN,
  UserRoles.DEALERSHIP_ADMIN,
  UserRoles.SALESMAN,
  UserRoles.APPRAISER,
  UserRoles.MANAGER,
  UserRoles.ASSESSOR,
];

export const CAR_IMAGE_PLACEHOLDER =
  'https://evaluation.hgregoire.com/assets/images/frontleftclear.png';
