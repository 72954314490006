import React, { FC } from 'react';
import { EditEvalFormProps } from '../../../physical-eval-edit/constants/types';
import useResetVehicle from './hooks/useResetVehicle';
import useBlackBookController from './hooks/useBlackBookController';
import { Countries } from 'graphql-types';
import { useUser } from 'store';
import { TextFieldController } from 'ui/inputs';
import { PeVehicleForm } from 'constants/forms';
import { LinearProgress, Stack } from '@mui/material';
import VinTitleBlock from './components/VinTitleBlock';
import { useFormContext } from 'react-hook-form';
import UnitConverter from 'utils/UnitConverter';
import RadioGroupController from 'ui/inputs/controllers/RadioGroup.controller';
import {
  EvaluationSourceOptions,
  VehicleColorsOptions,
} from 'constants/entities/ui/values';
import VehicleDetails from './components/VehicleDetails';

const VehicleForm: FC<EditEvalFormProps> = () => {
  useResetVehicle();

  const user = useUser();
  const { setValue } = useFormContext<PeVehicleForm.FormType>();

  const {
    seriesOptions,
    bodyTypeOptions,
    selectedSeries,

    handleSeriesChange,
    handleBodyTypeChange,

    isDecodeLoading,
    isDetailsLoading,
  } = useBlackBookController();

  const handleOdometerKmChange = (value: string) => {
    setValue(
      PeVehicleForm.Keys.OdometerMiles,
      UnitConverter.convertDistance(value, 'kilometers', 'miles').toString()
    );
  };

  const handleOdometerMilesChange = (value: string) => {
    setValue(
      PeVehicleForm.Keys.Odometer,
      UnitConverter.convertDistance(value, 'miles', 'kilometers').toString()
    );
  };

  return (
    <Stack gap={3} maxWidth={750} width="100%">
      <VinTitleBlock />

      <Stack gap={2} mt={1}>
        {user?.country === Countries.US && (
          <TextFieldController
            name={PeVehicleForm.Keys.OdometerMiles}
            label="appraisal.vehicle.odometer.label"
            changeHandler={handleOdometerMilesChange}
            type="number"
            required
            data-cy="odometer"
          />
        )}

        {user?.country === Countries.CA && (
          <TextFieldController
            name={PeVehicleForm.Keys.Odometer}
            label="appraisal.vehicle.odometer.label"
            changeHandler={handleOdometerKmChange}
            type="number"
            required
            data-cy="odometer"
          />
        )}

        <RadioGroupController
          name={PeVehicleForm.Keys.Origin}
          label="appraisal.vehicle.origin.label"
          options={EvaluationSourceOptions}
          required
        />

        {!!seriesOptions.length && (
          <RadioGroupController
            name={PeVehicleForm.Keys.Series}
            label="appraisal.vehicle.series.label"
            options={seriesOptions}
            changeHandler={handleSeriesChange}
            required
          />
        )}

        {selectedSeries && !!bodyTypeOptions.length && (
          <RadioGroupController
            name={PeVehicleForm.Keys.BodyType}
            label="appraisal.vehicle.body-type.label"
            options={bodyTypeOptions}
            changeHandler={handleBodyTypeChange}
            required
          />
        )}

        {!(isDecodeLoading || isDetailsLoading) && <VehicleDetails />}

        {(isDecodeLoading || isDetailsLoading) && (
          <LinearProgress sx={{ my: 2 }} />
        )}

        <Stack direction="row" gap={2} mt={2}>
          <TextFieldController
            name={PeVehicleForm.Keys.ExteriorColor}
            label="appraisal.vehicle.exterior-color.label"
            options={VehicleColorsOptions}
            select
            data-cy="exterior"
          />

          <TextFieldController
            name={PeVehicleForm.Keys.InteriorColor}
            label="appraisal.vehicle.interior-color.label"
            options={VehicleColorsOptions}
            select
            data-cy="interior"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VehicleForm;
