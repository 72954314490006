export const TOKEN_KEY = 'auth.token';

export const REACT_APP_MONKIA_DEV = process.env.REACT_APP_MONKIA_DEV || false;

export const CONDITION_SERVICE_API_URL =
  process.env.REACT_APP_CONDITION_SERVICE_API_URL || '';

export const CORE_SERVICE_API_URL =
  process.env.REACT_APP_CORE_SERVICE_API_URL || '';

if (!process.env.REACT_APP_API_URL)
  throw new Error('"REACT_APP_API_URL" is not defined in `.env` file');

export const API_URL = process.env.REACT_APP_API_URL;

if (!process.env.REACT_APP_WEB_SERVICE_API_URL)
  throw new Error(
    '"REACT_APP_WEB_SERVICE_API_URL" is not defined in `.env` file'
  );

export const WEB_SERVICE_API_URL = process.env.REACT_APP_WEB_SERVICE_API_URL;

if (!process.env.REACT_APP_USER_SERVICE_API_URL)
  throw new Error(
    '"REACT_APP_USER_SERVICE_API_URL" is not defined in `.env` file'
  );

export const USER_SERVICE_API_URL = process.env.REACT_APP_USER_SERVICE_API_URL;

if (!process.env.REACT_APP_NOTIFACTION_SERVICE_API_URL)
  throw new Error(
    '"NOTIFACTION_SERVICE_API_URL" is not defined in `.env` file'
  );

export const NOTIFACTION_SERVICE_API_URL =
  process.env.REACT_APP_NOTIFACTION_SERVICE_API_URL;

if (!process.env.REACT_APP_NOTIFACTION_SERVICE_WS_URL)
  throw new Error('"NOTIFACTION_SERVICE_WS_URL" is not defined in `.env` file');

export const NOTIFACTION_SERVICE_WS_URL =
  process.env.REACT_APP_NOTIFACTION_SERVICE_WS_URL;

if (!process.env.REACT_APP_SOCKET_URL)
  throw new Error('REACT_APP_SOCKET_URL is not defined in `.env` file');

export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

if (!process.env.REACT_APP_SOCKET_PATH)
  throw new Error('SOCKET_PATH is not defined in `.env` file');

export const SOCKET_PATH = process.env.REACT_APP_SOCKET_PATH;

if (!process.env.REACT_APP_SIGN_IN_URL)
  throw new Error('REACT_APP_SIGN_IN_URL is not defined in `.env` file');

export const SIGN_IN_URL = process.env.REACT_APP_SIGN_IN_URL;

if (!process.env.REACT_APP_GOOGLE_CLIENT_ID)
  throw new Error('REACT_APP_GOOGLE_CLIENT_ID is not defined in `.env` file');

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;

export const ROUTE_PREFIX = '/react';

export const VERSION = process.env.REACT_APP_VERSION || '0.0.0';

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
  'AIzaSyCoGv8yJ2gNVHnDPnOO15ogrJWsQDv2iBI';

export const MIN_SPEED_TO_UPLOAD_IMAGE_MbPS = 1; // Mega bits per 1 second
export const SPEED_TEST_INTERVAL = 40 * 1000; // milliseconds
