import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RestLink } from 'apollo-link-rest';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { split } from '@apollo/client';

import {
  API_URL,
  CONDITION_SERVICE_API_URL,
  USER_SERVICE_API_URL,
  NOTIFACTION_SERVICE_API_URL,
  NOTIFACTION_SERVICE_WS_URL,
  WEB_SERVICE_API_URL,
  CORE_SERVICE_API_URL,
} from 'constants/config/app-variables';
import { useAuth } from 'store';
import useRequestStore from './store/requests.store';

const authLink = setContext((_, { headers }) => {
  const { token } = useAuth.getState();

  return token
    ? {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
          webapp: 'support',
        },
      }
    : headers;
});

const errorLink = onError((error: any) => {
  if (error.networkError?.result?.result) {
    error.networkError.message = error.networkError?.result?.result;
  }
});

const httpLink = new HttpLink({
  uri: CONDITION_SERVICE_API_URL,
});

const restLink = new RestLink({
  endpoints: {
    backend: API_URL,
    webservice: WEB_SERVICE_API_URL,
    'user-service': USER_SERVICE_API_URL,
    'core-service': CORE_SERVICE_API_URL,
  },
  customFetch: (uri, options) => {
    if (uri.toString().includes(WEB_SERVICE_API_URL))
      return fetch(
        uri as string,
        { ...options, mode: 'navigate' } as RequestInit
      );

    return fetch(uri, options);
  },
  // This method replace fileds names witch not suportend in GRAPQQL
  // Filed '!' -> 'value'
  // Field '!some_key' -> 'some_key'
  fieldNameNormalizer: (key: string) => {
    if (key === '!') return 'value';

    return key.replace('!', '');
  },
});

const resolveMethod = {
  keyFields: ['result', ['id']],
  fields: {
    result: {
      merge(existing = [], incoming: any) {
        return { ...existing, ...incoming };
      },
    },
  },
};

const notifiactionHttpLink = new HttpLink({
  uri: NOTIFACTION_SERVICE_API_URL,
});

const notifiactionWsLink = new GraphQLWsLink(
  createClient({
    url: NOTIFACTION_SERVICE_WS_URL,
    connectionParams: () => {
      const { token } = useAuth.getState();

      return {
        Authorization: `Bearer ${token}`,
      };
    },
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  notifiactionWsLink, // web socket connection for subscriptions
  notifiactionHttpLink // http connection for query and mutation
);

const requestTrackingLink = new ApolloLink((operation: Operation, forward) => {
  const { incrementRequests, decrementRequests } = useRequestStore.getState();

  incrementRequests(operation.operationName);

  return new Observable((observer) => {
    const subscription = forward(operation).subscribe({
      next: (result) => observer.next(result),
      error: (error) => {
        decrementRequests(operation.operationName); // Decrement when there's an error
        observer.error(error);
      },
      complete: () => {
        decrementRequests(operation.operationName); // Decrement when the request completes
        observer.complete();
      },
    });

    // Cleanup if unsubscribed
    return () => subscription.unsubscribe();
  });
});

const notificationWebSocketEndpoint = ApolloLink.from([splitLink]);
const notificationRestEndpoint = ApolloLink.from([
  requestTrackingLink,
  authLink,
  notifiactionHttpLink,
]);

const mainEndpoint = ApolloLink.from([
  requestTrackingLink,
  authLink,
  errorLink,
  restLink,
  httpLink,
]);

export default new ApolloClient({
  connectToDevTools: true,
  link: new ApolloLink((operation, forward) => {
    switch (operation.getContext().clientName) {
      case 'notification-rest': {
        return notificationRestEndpoint.request(operation, forward);
      }
      case 'notification-subscription': {
        return notificationWebSocketEndpoint.request(operation);
      }
      default: {
        return mainEndpoint.request(operation, forward);
      }
    }
  }),
  cache: new InMemoryCache({
    typePolicies: {
      AppraisalMethod: resolveMethod,
      ListingMethod: resolveMethod,
      PhotoMethod: resolveMethod,
      UserMethod: resolveMethod,
      updateSettings: resolveMethod,
      updateUser: resolveMethod,
    },
  }),
});
