import { MIN_SPEED_TO_UPLOAD_IMAGE_MbPS } from 'constants/config/app-variables';
import { useRef } from 'react';
import { useOnlineStore } from '../OnlineStatusProvider';
import { useAuth } from 'store';
import useTestSpeedAbility from './useTestSpeedAbility/useTestSpeedAbility';

const useCheckSpeedAbilities = () => {
  const { token } = useAuth();

  const { testNetworkSpeed } = useTestSpeedAbility();

  const { isSpeedTestLoading, isOnline, setUploadImageAbility } =
    useOnlineStore(
      ({ isOnline, setUploadImageAbility, isSpeedTestLoading }) => ({
        setUploadImageAbility,
        isOnline,
        isSpeedTestLoading,
      })
    );

  const loadingRef = useRef<boolean>(false);
  loadingRef.current = isSpeedTestLoading;

  const isOnlineRef = useRef<boolean>(false);
  isOnlineRef.current = isOnline;

  const tokenRef = useRef<string | null>(null);
  tokenRef.current = token;

  const checkSpeedAbilities = async () => {
    /*
      Prevent sending multiple requests
      and sending requests in inactive app mode
    */
    if (loadingRef.current || document.hidden) return false;

    if (isOnlineRef.current) {
      /*
        Check is device connected to the Internet
      */

      if (!tokenRef.current) {
        /*
          Without the token we can`t test the speed.
          So we enable UploadImageAbility by default.
        */

        setUploadImageAbility(true);

        return false;
      }

      /*
        Connection with the Internet is established.
        Proceed to speed testing ✔
       */
    } else {
      /*
        If device is not connected to the Internet -
        there is no way to upload an images
      */
      setUploadImageAbility(false);
      return true;
    }

    let isSpeedEnough = false;
    let isSuccess = true;

    for (const i of [0, 1, 2]) {
      const result = await testNetworkSpeed({
        minSpeedMbps: MIN_SPEED_TO_UPLOAD_IMAGE_MbPS,
      });

      if (result.isSpeedEnough) {
        isSpeedEnough = true;
        isSuccess = result.isSuccess;
      }
    }

    if (isSuccess) {
      setUploadImageAbility(isSpeedEnough);
      return true;
    } else {
      /*
        User is able to load images if we can`t calculate the speed
      */
      setUploadImageAbility(true);
      return false;
    }
  };

  return { checkSpeedAbilities };
};

export default useCheckSpeedAbilities;
