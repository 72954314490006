import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { defaultUser, useAuth, useSidebar } from 'store';
import { useUser, useUserChecks } from 'store';
import { useFeatures } from 'store';

import LayoutSuspense from '../layout-suspend';
import Sidenav from '../sidenav';
import Topbar from '../toolbar';
import VerticalNav from '../vertical-nav';
import { navigations } from '../navigationsMenu';
import NotificationsBar from '../notifications';
import { Countries } from 'graphql-types';
import { RoutesConfig } from '../../../router/routes.config';
import { Permissions } from '../../../constants/entities/user';
import useUserStore from '../../../store/user/user.store';
import OfflineBar from './comonents/OfflineBar';

const useStyles = makeStyles({
  contentWrap: ({ width }: { width: string }) => ({
    verticalAlign: 'top',
    marginLeft: width,
    transition: 'all 0.3s ease',
    height: '100dvh',
  }),
});

const AppLayout = (props: PropsWithChildren) => {
  const theme = useTheme();
  const { sidebarMode, changeMode, closeSidebar } = useSidebar();
  const { logout } = useAuth();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const ref = useRef({ isMdScreen, sidebarMode });
  const {
    isFeatureAdminEnabled,
    isConditionManagementEnabled,
    isFeatureAnalyticEnabled,
  } = useFeatures();

  const user = useUser();
  const { isHasAccess } = useUserChecks();
  const { setUser } = useUserStore();
  const isAdmin = isHasAccess(Permissions.Dashboard.GetGlobalStatistic);

  const handleLogout = () => {
    logout();
    setUser(defaultUser);
  };

  const navigationsFilter =
    user?.country === Countries.CA
      ? navigations.filter((it) => it.key !== 'dashboard-statistic-my')
      : navigations
          .filter((it) => {
            const whatDelete = isAdmin
              ? 'dashboard-statistic-my'
              : 'dashboard-statistic';

            return it.key !== whatDelete;
          })
          .filter((it) => !it.key.includes('virtual-evaluations'));

  const navigationsModern = [
    ...navigationsFilter,
    isFeatureAnalyticEnabled && {
      key: 'evaluation-analytics-label',
      type: 'label',
    },
    isFeatureAdminEnabled && {
      key: 'evaluation-admin',
      type: 'label',
    },
    isFeatureAdminEnabled && {
      key: 'feature',
      path: '/feature/',
      icon: 'dashboard',
      type: 'link',
    },
    isConditionManagementEnabled && {
      key: 'conditions-manager',
      path: RoutesConfig.ConditionDamage.List,
      icon: 'build',
      type: 'link',
    },
    isConditionManagementEnabled && {
      key: 'procent-settings',
      path: RoutesConfig.PercentSettings,
      icon: 'build',
      type: 'link',
    },
  ]
    .filter((it) => !!it)
    .map((it) => it as NavigationItem);

  const ruleNavigation = navigationsModern
    .filter((nav) => {
      return nav?.permission ? isHasAccess(nav?.permission) : true;
    })
    .map((it) =>
      it.type == 'link'
        ? {
            ...it,
            path: it.path,
          }
        : it
    );

  useEffect(() => {
    const sidebarMode = ref.current.sidebarMode;
    changeMode(isMdScreen ? 'close' : sidebarMode);
  }, [ref, isMdScreen]);

  const handleSidebarToggle = () => {
    if (isMdScreen) {
      changeMode(sidebarMode === 'close' ? 'mobile' : 'close');
    } else {
      changeMode(sidebarMode === 'full' ? 'close' : 'full');
    }
  };

  const [notificationsBar, setNotificationsBar] = useState<boolean>(false);
  const handleNotificationsBarToggle = () => {
    setNotificationsBar((prev) => !prev);
  };

  const getSidenavWidth = () => {
    switch (sidebarMode) {
      case 'full':
        return 'var(--sidenav-width)';
      case 'compact':
        return 'var(--sidenav-compact-width)';
      default:
        return '0px';
    }
  };

  const sidenavWidth = getSidenavWidth();
  const classes = useStyles({ width: sidenavWidth });

  return (
    <div className={'bg-default'}>
      {sidebarMode !== 'close' && (
        <Sidenav mode={sidebarMode} onOverlayClick={closeSidebar}>
          <VerticalNav items={ruleNavigation} />
        </Sidenav>
      )}

      {notificationsBar && (
        <NotificationsBar onOverlayClick={handleNotificationsBarToggle} />
      )}

      <div
        className={clsx(
          'flex-grow flex-column relative overflow-hidden',
          classes.contentWrap
        )}
      >
        <Topbar
          userName={`${user?.firstName} ${user?.lastName}`}
          onSidebarToggle={handleSidebarToggle}
          onNotificationsBarToggle={handleNotificationsBarToggle}
          onLogout={handleLogout}
        />

        <Box className="flex-grow flex-column relative h-full scroll-y">
          <div className="relative flex-grow">
            <Box
              id="layout"
              className="flex-grow flex-column relative h-full"
              p={{ xs: 1, sm: 1, md: 3 }}
            >
              <LayoutSuspense>{props.children}</LayoutSuspense>
            </Box>
          </div>
        </Box>

        <OfflineBar />
        {/*<UnsavedConditions />*/}
      </div>
    </div>
  );
};

export default AppLayout;
