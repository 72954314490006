import { useConditionStore } from './conditions.store';
import {
  useGetAppraisalMutation,
  useUpdateAppraisalMutation,
  useUploadAppraisalPhotoReactiveMutation,
} from 'graphql-types';
import ComplexIdGenerator from 'utils/ComplexIdGenerator';
import { typedAppraisal } from 'utils';
import { ConditionIdType, ConditionPhotosItem } from './types';

const useUploadConditionItem = () => {
  const [getAppraisal] = useGetAppraisalMutation();
  const [uploadAppraisalPhoto] = useUploadAppraisalPhotoReactiveMutation();
  const { forceRemoveCondition, removePhotos, setLoading } =
    useConditionStore();
  const [update] = useUpdateAppraisalMutation();

  const uploadConditionItem = async ({
    appraisalId,
    photos,
    conditionId,
  }: ConditionPhotosItem) => {
    try {
      setLoading(appraisalId, conditionId, true);

      /*
        Step 1. Fetch an appraisal data
      */
      const { data } = await getAppraisal({
        variables: { id: appraisalId.toString(), withInventory: false },
      });

      /*
        Step 2. Check is appraisal still exists?

        Yes -> Proceed to Step 3.
        No -> Remove condition from IndexDB
      */

      if (!data?.getAppraisal) {
        setLoading(appraisalId, conditionId, false);
        throw new Error('Unable to fetch appraisal');
      }

      /*
        Step 3. Check is condition still exists

        Yes -> Proceed to Step 4.
        No -> Remove condition from IndexDB
      */

      const { label, pinX, pinY } =
        ComplexIdGenerator.parse<ConditionIdType>(conditionId);

      const selectedCondition = data.getAppraisal.result.conditions.find(
        (condition) =>
          condition.label === label &&
          condition.pin.x === pinX &&
          condition.pin.y === pinY
      );

      if (!selectedCondition) {
        /*
         The condition has been removed from server, so there is no need to upload it`s photos
      */

        setLoading(appraisalId, conditionId, false);
        return forceRemoveCondition(appraisalId, conditionId);
      }

      /*
      Step 2. Upload photos one by one and push each on server
     */

      for (const photo of photos) {
        const isPhotoStillExists = selectedCondition.photos.some(
          ({ src }) => src === photo.serverSrcId
        );

        if (isPhotoStillExists) {
          const uploadedPhoto = await uploadAppraisalPhoto({
            variables: { id: appraisalId, file: photo },
          });

          // TODO: Photo is loaded
          // Check is no update mutation in progress, wait if there are
          // Replace current photo src

          const srcS3 =
            uploadedPhoto.data?.uploadAppraisalPhotoReactive.result.src || '';

          const updatedConditions = data.getAppraisal.result.conditions.map(
            (condition) => {
              const isConditionSame =
                condition.label === label &&
                condition.pin.x === pinX &&
                condition.pin.y === pinY;

              if (isConditionSame) {
                return {
                  ...condition,
                  photos: condition.photos.map((p) =>
                    p.src === photo.serverSrcId ? { ...p, src: srcS3 } : p
                  ),
                };
              }

              return condition;
            }
          );

          /*
            Step 3. Update appraisal condition with replaced photo
          */

          const jsonString = JSON.stringify(
            typedAppraisal({
              ...data.getAppraisal.result,
              conditions: updatedConditions,
            })
          );

          await update({ variables: { input: { jsonString } } });

          data.getAppraisal.result.conditions = updatedConditions;

          removePhotos(appraisalId, conditionId, [photo.serverSrcId]);
        } else {
          /*
            Photo has been removed from server, so there is no need to upload it
          */
          removePhotos(appraisalId, conditionId, [photo.serverSrcId]);
        }
      }
    } catch (err) {
      throw Error('error');
    } finally {
      setLoading(appraisalId, conditionId, false);
    }
  };

  return { uploadConditionItem };
};

export default useUploadConditionItem;
