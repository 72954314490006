import { useMatch, useSearchParams } from 'react-router-dom';
import { RoutesConfig } from 'router/routes.config';

const useIsOfflinePage = () => {
  const physicalMatch = useMatch(RoutesConfig.PhysicalEvaluation.Edit);
  const assessorMatch = useMatch(RoutesConfig.Assessor.View);
  const [searchParams] = useSearchParams();

  const isPhysicalPictures =
    physicalMatch?.pattern.path === RoutesConfig.PhysicalEvaluation.Edit &&
    searchParams.get('step') === '4';

  const isConditionDamages =
    assessorMatch?.pattern.path === RoutesConfig.Assessor.View;

  return isPhysicalPictures || isConditionDamages;
};

export default useIsOfflinePage;
