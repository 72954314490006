import { create } from 'zustand';
import { ConditionStore } from './types';
import ConditionsEvent from './ConditionsEvent';
export const useConditionStore = create<ConditionStore>((set, get) => ({
  conditionPhotos: [],
  setConditionPhotos: (conditionPhotos) => {
    set({ conditionPhotos });
  },

  getCondition: (appraisalId, conditionId) => {
    return get().conditionPhotos.find(
      (c) => c.appraisalId === appraisalId && c.conditionId === conditionId
    );
  },

  saveCondition: (item) => {
    set((state) => {
      // Check if the condition already exists
      const existingConditionIndex = state.conditionPhotos.findIndex(
        ({ appraisalId, conditionId }) =>
          appraisalId === item.appraisalId && conditionId === item.conditionId
      );

      if (existingConditionIndex !== -1) {
        // If it exists, merge the photos
        const existingCondition = state.conditionPhotos[existingConditionIndex];

        const updatedPhotos = [
          ...existingCondition.photos,
          ...item.photos, // Combine existing photos with new ones
        ];

        // Update the condition with combined photos
        const updatedCondition = {
          ...existingCondition,
          photos: updatedPhotos,
        };

        if (item.photos.length) {
          ConditionsEvent.emit('savedCondition', {
            ...item,
            isUploading: false,
          });
        }

        // Return updated conditionPhotos with the merged photos
        return {
          conditionPhotos: [
            ...state.conditionPhotos.slice(0, existingConditionIndex),
            updatedCondition,
            ...state.conditionPhotos.slice(existingConditionIndex + 1),
          ],
        };
      } else {
        ConditionsEvent.emit('savedCondition', {
          ...item,
          isUploading: false,
        });

        // If it doesn't exist, just add the new item with isUploading: false

        return {
          conditionPhotos: [
            { ...item, isUploading: false },
            ...state.conditionPhotos,
          ],
        };
      }
    });
  },

  removeCondition: (appraisalId, conditionId) => {
    set((state) => ({
      conditionPhotos: state.conditionPhotos.filter(
        (c) =>
          !(
            c.conditionId === conditionId &&
            c.appraisalId === appraisalId &&
            !c.photos.length
          )
      ),
    }));
  },

  forceRemoveCondition: (appraisalId, conditionId) => {
    set((state) => ({
      conditionPhotos: state.conditionPhotos.filter(
        (c) => !(c.conditionId === conditionId && c.appraisalId === appraisalId)
      ),
    }));
  },

  removePhotos: (appraisalId, conditionId, removeServerIds) => {
    set((state) => ({
      conditionPhotos: state.conditionPhotos.map((condition) => {
        const isSameCondition =
          condition.conditionId === conditionId &&
          condition.appraisalId === appraisalId;

        if (isSameCondition) {
          return {
            ...condition,
            photos: condition.photos.filter(
              ({ serverSrcId }) => !removeServerIds.includes(serverSrcId)
            ),
          };
        }

        return condition;
      }),
    }));
  },

  setLoading: (appraisalId, conditionId, isUploading) => {
    set((state) => ({
      conditionPhotos: state.conditionPhotos.map((condition) =>
        condition.appraisalId === appraisalId &&
        condition.conditionId === conditionId
          ? { ...condition, isUploading }
          : condition
      ),
    }));
  },

  isLoading: (appraisalId, conditionId) => {
    return get().conditionPhotos.some((c) => {
      return (
        c.appraisalId === appraisalId &&
        c.conditionId === conditionId &&
        c.isUploading
      );
    });
  },

  getPhotoSrc: (serverSrcId) => {
    return get().conditionPhotos.reduce<string | null>((acc, condition) => {
      const photo = condition.photos.find((p) => p.serverSrcId === serverSrcId);

      if (photo) {
        return photo.src;
      }

      return acc;
    }, null);
  },

  isProcessingQueue: false,
  setProcessingQueue: (isProcessingQueue) => set({ isProcessingQueue }),
}));
