import {
  useOnlineStore,
  useUploadImageAbility,
} from 'contexts/OnlineStatusProvider/OnlineStatusProvider';
import { useConditionStore } from './conditions.store';
import { useEffect, useRef } from 'react';
import useUploadConditionItem from './useUploadConditionItem';
import { ConditionPhotosItem } from './types';

const maxRetries = 2;

const useConditionsSync = () => {
  const isAbleUploadImages = useUploadImageAbility();
  const isAbleUploadImagesRef = useRef<boolean>(false);
  isAbleUploadImagesRef.current = isAbleUploadImages;

  const { isSpeedTestCompleted } = useOnlineStore((state) => ({
    isSpeedTestCompleted: state.isSpeedTestCompleted,
  }));
  const testCompletedRef = useRef(false);
  testCompletedRef.current = isSpeedTestCompleted;

  const {
    conditionPhotos,
    removeCondition,
    isProcessingQueue,
    setProcessingQueue,
  } = useConditionStore();

  const conditionsRef = useRef<ConditionPhotosItem[]>([]);
  conditionsRef.current = conditionPhotos;

  const { uploadConditionItem } = useUploadConditionItem();

  const isProcessingQueueRef = useRef(false); // Flag to prevent multiple queue processes
  isProcessingQueueRef.current = isProcessingQueue;

  const uploadConditionPhotos = async (condition: ConditionPhotosItem) => {
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        attempt++;
        await uploadConditionItem(condition);

        removeCondition(condition.appraisalId, condition.conditionId);

        return;
      } catch (error) {
        if (attempt >= maxRetries) {
          // Do nothing, condition remains in Zustand store and will be retried
          return;
        }

        // Trying again...
      }
    }
  };

  const processNextCondition = async () => {
    const nextCondition = conditionsRef.current[0];

    if (
      !testCompletedRef.current ||
      isProcessingQueueRef.current ||
      !nextCondition
    ) {
      return; // Prevent multiple uploads running concurrently
    }

    setProcessingQueue(true);

    try {
      await uploadConditionPhotos(nextCondition);
    } finally {
      setProcessingQueue(false); // Reset flag after processing

      setTimeout(() => {
        processNextCondition();
      }, 1000);
    }
  };

  useEffect(() => {
    processNextCondition();
  }, [conditionPhotos, isSpeedTestCompleted]);
};

export default useConditionsSync;
