import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';

import {
  useAppraisalsCountQuery,
  useListingsCountQuery,
  useNotificationCountQuery,
  useNotificationListQuery,
} from 'graphql-types';
import { useSocket } from 'hooks';
import { useUserChecks } from 'store';
import { useLocation } from 'react-router-dom';
import { Permissions } from '../constants/entities/user';
import dayjs from 'dayjs';

export type NotificationItem = {
  _id: string;
  id: number;
  created: string;
  group: string;
  text: string;
  source: {
    type: string;
    id: number;
  };
  read_by: string[];
  received_by: string[];
};

type GlobalContextType = {
  appraisal: {
    pending: number;
    evaluation: number;
    evaluating: number;
    assessment: number;
    claim: number;
  };
  listing: {
    evaluation: number;
    evaluating: number;
  };
  notification: {
    count: number;
    list: NotificationItem[];
  };
  refetchAppraisalsHandle: () => void;
};

const GlobalContext = createContext<GlobalContextType>({
  appraisal: {
    pending: 0,
    evaluation: 0,
    evaluating: 0,
    assessment: 0,
    claim: 0,
  },
  listing: {
    evaluation: 0,
    evaluating: 0,
  },
  notification: {
    count: 0,
    list: [],
  },
  refetchAppraisalsHandle: () => {
    return null;
  },
});

export const GlobalProvider = (props: PropsWithChildren) => {
  const { pathname } = useLocation();
  const { isHasAccess, isSocketUsing } = useUserChecks();

  const isEditPhysical =
    pathname.split('/').includes('edit') &&
    pathname.split('/').includes('physical-evaluation');

  const { data: appraisalsCount, refetch: refetchAppraisals } =
    useAppraisalsCountQuery(
      isSocketUsing() && isHasAccess(Permissions.PhysicalEvaluation.GetList)
        ? {}
        : { skip: true }
    );

  const { data: listingsCount, refetch: refetchListinig } =
    useListingsCountQuery(
      isSocketUsing() && isHasAccess(Permissions.VirtualEvaluation.GetList)
        ? {
            variables: {
              start: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
              end: dayjs().format('YYYY-MM-DD'),
            },
          }
        : { skip: true }
    );

  const { data: notificationCount, refetch: refetchNotification } =
    useNotificationCountQuery();

  const { data: notificationList, refetch: refetchNotificationList } =
    useNotificationListQuery();

  const { bus } = useSocket();

  const refetchAppraisalsHandle = () => {
    if (
      isSocketUsing() &&
      isHasAccess(Permissions.PhysicalEvaluation.GetList)
    ) {
      refetchAppraisals();
    }

    refetchNotification();
    refetchNotificationList();
  };

  const value = useMemo(
    () => ({
      appraisal: {
        pending: appraisalsCount?.pendingAppraisalsCount.result || 0,
        evaluation: appraisalsCount?.evaluationAppraisalsCount.result || 0,
        evaluating: appraisalsCount?.evaluatingAppraisalsCount.result || 0,
        assessment: appraisalsCount?.assessmentAppraisalsCount.result || 0,
        claim: appraisalsCount?.claimAppraisalsCount.result || 0,
      },
      listing: {
        evaluation: listingsCount?.evaluationListingsCount.result || 0,
        evaluating: listingsCount?.evaluatingListingsCount.result || 0,
      },
      notification: {
        count: notificationCount?.notificationCount.result || 0,
        list: notificationList?.notificationList.result || [],
      },
      refetchAppraisalsHandle,
    }),
    [appraisalsCount, listingsCount, notificationCount, notificationList]
  );

  useEffect(() => {
    if (!isEditPhysical) {
      bus.on('appraisal', (data: any) => {
        refetchAppraisalsHandle();
      });

      bus.on('listing', (data: any) => {
        if (
          isSocketUsing() &&
          isHasAccess(Permissions.VirtualEvaluation.GetList)
        ) {
          refetchListinig();
        }

        refetchNotification();
        refetchNotificationList();
      });

      bus.on('notices', (data: any) => {
        refetchNotification();
        refetchNotificationList();
      });

      bus.on('noticesCount', (data: any) => {
        refetchNotification();
        refetchNotificationList();
      });

      bus.on('noticesRead', (data: any) => {
        refetchNotification();
        refetchNotificationList();
      });
    }
  }, [bus]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
