import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useRef,
} from 'react';
import { createStore, useStore } from 'zustand';
import { StoreApi } from 'zustand/vanilla';
import { OnlineStatusStore } from './types';
import NetworkStatusDetection from './NetworkStatusDetection';
import NetworkEvent from './NetworkEvent';

const StoreContext = createContext<StoreApi<OnlineStatusStore>>(
  {} as StoreApi<any>
);

const OnlineStoreProvider: FC<PropsWithChildren> = ({ children }) => {
  const storeRef = useRef<StoreApi<OnlineStatusStore> | null>(null);

  if (!storeRef.current) {
    storeRef.current = createStore((set) => ({
      isOnline: true,
      setOnline: (isOnline) => {
        set({ isOnline });

        if (isOnline) {
          NetworkEvent.emit('online');
        } else {
          NetworkEvent.emit('offline');
        }
      },

      isUploadImageAbility: true,
      setUploadImageAbility: (isUploadImageAbility) => {
        set({ isUploadImageAbility });

        setTimeout(() => {
          if (isUploadImageAbility) {
            NetworkEvent.emit('ableUploadImages');
          } else {
            NetworkEvent.emit('unableUploadImages');
          }
        }, 1000);
      },

      isSpeedTestLoading: false,
      setSpeedTestLoading: (isSpeedTestLoading) => set({ isSpeedTestLoading }),

      isSpeedTestCompleted: false,
      setSpeedTestCompleted: (isSpeedTestCompleted) =>
        set({ isSpeedTestCompleted }),
    }));
  }

  return (
    <StoreContext.Provider
      value={storeRef.current as StoreApi<OnlineStatusStore>}
    >
      {children}

      <NetworkStatusDetection />
    </StoreContext.Provider>
  );
};

export const useOnlineStore = <Selected = Partial<OnlineStatusStore>,>(
  selector: (state: OnlineStatusStore) => Selected
) => {
  const store = useContext<StoreApi<OnlineStatusStore>>(StoreContext);

  if (!store) {
    throw new Error('Missing OnlineStatusProvider');
  }

  return useStore(store, selector);
};

export const useIsOnline = () => {
  return useOnlineStore(({ isOnline }) => isOnline);
};

export const useUploadImageAbility = () => {
  return useOnlineStore(({ isUploadImageAbility }) => isUploadImageAbility);
};

export const useSpeedTestCompleted = () => {
  return useOnlineStore(({ isSpeedTestCompleted }) => isSpeedTestCompleted);
};

export default OnlineStoreProvider;
