import { CORE_SERVICE_API_URL } from 'constants/config/app-variables';
import { useAuth } from 'store';
import testImg from './test-speed.jpg';
import { useEffect, useRef } from 'react';
import { SpeedWorkerResponse } from '../../types';
import { useOnlineStore } from '../../OnlineStatusProvider';

const createFileFromImage = async (
  imageSrc: string,
  fileName: string,
  fileType: string
): Promise<File> => {
  const response = await fetch(imageSrc);
  const blob = await response.blob();
  return new File([blob], fileName, { type: fileType });
};

const url =
  CORE_SERVICE_API_URL + '/private/physical-evaluation/test-load-image';

const speedAbilityWorker = new Worker(
  new URL('./speedAbilityWorker.ts', import.meta.url)
);

type Options = { minSpeedMbps: number };

const useTestSpeedAbility = () => {
  const { token } = useAuth();
  const tokenRef = useRef<string | null>(null);
  tokenRef.current = token;

  const { setSpeedTestLoading } = useOnlineStore(({ setSpeedTestLoading }) => ({
    setSpeedTestLoading,
  }));

  const imageFilePromiseRef = useRef<null | Promise<File>>();

  useEffect(() => {
    imageFilePromiseRef.current = createFileFromImage(
      testImg,
      'test.jpeg',
      'image/jpeg'
    );
  }, []);

  const testNetworkSpeed = async ({ minSpeedMbps }: Options) => {
    setSpeedTestLoading(true);

    const file = await imageFilePromiseRef.current;

    return new Promise<SpeedWorkerResponse>((resolve) => {
      speedAbilityWorker.postMessage({
        file,
        url,
        token: tokenRef.current,
        minSpeedMbps,
      });

      speedAbilityWorker.onmessage = (e: MessageEvent<SpeedWorkerResponse>) => {
        setSpeedTestLoading(false);
        resolve(e.data);
      };
    });
  };

  return { testNetworkSpeed };
};

export default useTestSpeedAbility;
