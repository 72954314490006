import { createTheme, Shadows } from '@mui/material/styles';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export const shadows: Shadows = [
  'none',
  '0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 1px -2px rgba(0, 0, 0, 0.06),0px 2px 2px 0px rgba(0, 0, 0, 0.042),0px 1px 5px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 3px -2px rgba(0, 0, 0, 0.06),0px 3px 4px 0px rgba(0, 0, 0, 0.042),0px 1px 8px 0px rgba(0, 0, 0, 0.036)',
  '0px 2px 4px -1px rgba(0, 0, 0, 0.06),0px 4px 5px 0px rgba(0, 0, 0, 0.042),0px 1px 10px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 5px 8px 0px rgba(0, 0, 0, 0.042),0px 1px 14px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 6px 10px 0px rgba(0, 0, 0, 0.042),0px 1px 18px 0px rgba(0, 0, 0, 0.036)',
  '0px 4px 5px -2px rgba(0, 0, 0, 0.06),0px 7px 10px 1px rgba(0, 0, 0, 0.042),0px 2px 16px 1px rgba(0, 0, 0, 0.036)',
  '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
  '0px 5px 6px -3px rgba(0, 0, 0, 0.06),0px 9px 12px 1px rgba(0, 0, 0, 0.042),0px 3px 16px 2px rgba(0, 0, 0, 0.036)',
  '0px 6px 6px -3px rgba(0, 0, 0, 0.06),0px 10px 14px 1px rgba(0, 0, 0, 0.042),0px 4px 18px 3px rgba(0, 0, 0, 0.036)',
  '0px 6px 7px -4px rgba(0, 0, 0, 0.06),0px 11px 15px 1px rgba(0, 0, 0, 0.042),0px 4px 20px 3px rgba(0, 0, 0, 0.036)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 12px 17px 2px rgba(0, 0, 0, 0.042),0px 5px 22px 4px rgba(0, 0, 0, 0.036)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 13px 19px 2px rgba(0, 0, 0, 0.042),0px 5px 24px 4px rgba(0, 0, 0, 0.036)',
  '0px 7px 9px -4px rgba(0, 0, 0, 0.06),0px 14px 21px 2px rgba(0, 0, 0, 0.042),0px 5px 26px 4px rgba(0, 0, 0, 0.036)',
  '0px 8px 9px -5px rgba(0, 0, 0, 0.06),0px 15px 22px 2px rgba(0, 0, 0, 0.042),0px 6px 28px 5px rgba(0, 0, 0, 0.036)',
  '0px 8px 10px -5px rgba(0, 0, 0, 0.06),0px 16px 24px 2px rgba(0, 0, 0, 0.042),0px 6px 30px 5px rgba(0, 0, 0, 0.036)',
  '0px 8px 11px -5px rgba(0, 0, 0, 0.06),0px 17px 26px 2px rgba(0, 0, 0, 0.042),0px 6px 32px 5px rgba(0, 0, 0, 0.036)',
  '0px 9px 11px -5px rgba(0, 0, 0, 0.06),0px 18px 28px 2px rgba(0, 0, 0, 0.042),0px 7px 34px 6px rgba(0, 0, 0, 0.036)',
  '0px 9px 12px -6px rgba(0, 0, 0, 0.06),0px 19px 29px 2px rgba(0, 0, 0, 0.042),0px 7px 36px 6px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
];

export const typography = {
  allVariants: {
    color: '#1d1d1d',
  },
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  h1: {
    fontSize: 32,
    lineHeight: '38px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  h2: {
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  h3: {
    fontSize: 18,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  h4: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  h5: {
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  body1: {
    fontSize: 15,
    lineHeight: '24px',
    letterSpacing: 0,
    fontWeight: 400,
  },
  body2: {
    fontSize: 13,
    lineHeight: '16px',
    letterSpacing: 0,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0,
    fontWeight: 700,
  },
  subtitle2: {
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0,
    fontWeight: 700,
  },
};

export const palette = {
  primary: { main: '#18a857', contrastText: '#ffffff' },
  secondary: { main: '#FFDC00', contrastText: '#414141' },

  // legacy (todo: remove, use red)
  error: {
    main: '#FF3D57',
  },

  white: '#ffffff',

  grey: {
    1000: '#3c3c3b',
    900: '#636362',
    800: '#7c7c7c',
    700: '#9c9c9b',
    600: '#c7c7c7',
    500: '#ececeb',
    400: '#fbfbfa',
  },

  green: {
    900: '#18a857',
    800: '#46b979',
    700: '#74cb9a',
    600: '#a3dcbc',
    550: '#d1eedd',
    500: '#ffffff',
  },

  red: {
    1000: '#F21443',
    900: '#F54369',
    800: '#F66281',
    700: '#FBB6C5',
    600: '#FEE8EC',
  },

  blue: {
    1100: '#2D598A',
    1000: '#1570EF',
    500: '#EBF0F9',
  },

  yellow: {
    1000: '#E38100',
    900: '#f89d27',
    700: '#FFE5A6',
    600: '#FCEBC1',
  },

  system: {
    error: '#F04438',
    alert: '#E38100',
    success: '#039855',
    info: '#1570EF',
  },
};

const themes = {
  hgregoire: createTheme({
    shadows,
    typography,
    palette,

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
            {
              '-webkit-appearance': 'none',
              margin: 0,
            },

          '::-webkit-scrollbar': {
            width: '12px',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: palette.grey[600],
            borderRadius: '6px',
            border: '3px solid #ffffff',
          },
          '::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '6px',
          },
          '::-webkit-scrollbar-thumb:hover': {
            backgroundColor: palette.grey[700],
          },
          '::-webkit-scrollbar-corner': {
            backgroundColor: '#f1f1f1',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 'initial',
          },
          sizeSmall: { height: 36 },
          sizeMedium: { height: 48 },
          sizeLarge: { height: 56 },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            margin: 4,
            border: 0,
            borderRadius: 5,

            '&.Mui-disabled': { border: '0' },
          },
        },
      },

      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',

            '&:not:first-of-type': {
              marginLeft: -1,
              borderLeft: '1px solid transparent',
            },

            '&.Mui-selected, &.Mui-selected:hover': {
              background: palette.green['800'],
              color: palette.white,
            },
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: palette.grey[800],
            ...typography.body1,
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputLabel-shrink': {
              fontSize: '18px',
            },

            '& fieldset span': {
              paddingRight: 15,
            },
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          root: { marginTop: 10 },
        },
      },

      MuiStepConnector: {
        styleOverrides: {
          line: {
            borderColor: palette.grey[600],
          },
        },
      },

      MuiStepLabel: {
        styleOverrides: {
          root: {
            '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
              marginTop: 10,

              '& > div': {
                gap: 2,
              },
            },
          },
        },
      },

      MuiTableContainer: {
        styleOverrides: {
          root: {
            overflowX: 'initial',
          },
        },
      },

      MuiTableCell: {
        styleOverrides: {
          head: {
            padding: '8px 16px',
            background: palette.grey[500],

            '&:first-of-type': {
              borderRadius: '8px 0 0 8px',
            },

            '&:last-of-type': {
              borderRadius: '0 8px 8px 0',
            },
          },

          body: {
            padding: '8px 16px',
          },
        },
      },
    },
  }),
};

export default themes;
