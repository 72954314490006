import React, { FC, useEffect, useState, useRef } from 'react';
import { Icon, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsOnline } from 'contexts/OnlineStatusProvider';

const OfflineBar: FC = () => {
  const { t } = useTranslation();
  const isOnline = useIsOnline();

  const [showBackOnlineBar, setShowBackOnlineBar] = useState(false);
  const hasBeenOffline = useRef(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (!isOnline) {
      hasBeenOffline.current = true;
    }

    if (isOnline && hasBeenOffline.current) {
      setShowBackOnlineBar(true);

      timeout = setTimeout(() => {
        setShowBackOnlineBar(false);
        hasBeenOffline.current = false;
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [isOnline]);

  if (isOnline && !showBackOnlineBar) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: isOnline ? 'green.700' : 'grey.700',
        px: 2,
        py: 0.5,
        gap: 1,
      }}
    >
      <Icon sx={{ color: 'white' }}>{isOnline ? 'wifi' : 'wifi_off'}</Icon>

      <Typography color="white">
        {isOnline ? t('offline.established') : t('offline.no-connection')}
      </Typography>
    </Stack>
  );
};

export default OfflineBar;
