class ComplexIdGenerator {
  static create(obj: Record<string, string | number>) {
    return Object.entries(obj).reduce((acc, [key, value], i) => {
      return `${acc}${i === 0 ? '' : ';;'}${key}::${value}`;
    }, '');
  }

  static parse<R = Record<string, string | number>>(id: string): R {
    return id.split(';;').reduce((acc: R, part) => {
      const [key, value] = part.split('::');

      (acc as any)[key] = isNaN(Number(value)) ? value : Number(value);

      return acc;
    }, {} as R);
  }
}

export default ComplexIdGenerator;
