import { useConditionStore } from './conditions.store';
import { useEffect, useRef } from 'react';
import localForage from 'localforage';

const KEY = 'condition-report-store';

const useStoreDB = () => {
  const { setConditionPhotos, conditionPhotos } = useConditionStore();
  const initializedRef = useRef(false);

  // Load data from IndexedDB and set it in Zustand store
  const init = async () => {
    try {
      const photosJSON = await localForage.getItem(KEY);
      const photos =
        JSON.parse(photosJSON as string)?.state?.conditionPhotos ?? [];

      setConditionPhotos(photos);

      initializedRef.current = true; // Mark initialization as complete
    } catch (error) {
      console.error('Failed to load photos from IndexedDB:', error);
    }
  };

  // Save Zustand state to IndexedDB
  const push = async () => {
    try {
      const JSONString = JSON.stringify({ state: { conditionPhotos } });

      await localForage.setItem(KEY, JSONString);
    } catch (error) {
      console.error('Failed to save photos to IndexedDB:', error);
    }
  };

  useEffect(() => {
    init(); // Load data on mount
  }, []);

  useEffect(() => {
    // Push updates to IndexedDB only after initialization
    if (initializedRef.current) {
      push();
    }
  }, [conditionPhotos]);
};

export default useStoreDB;
