import { EventEmitter } from 'events';

interface AppEvents {
  speedCalculated: void;
  online: void;
  offline: void;
  ableUploadImages: void;
  unableUploadImages: void;
}

class TypedEventEmitter extends EventEmitter {
  emit<K extends keyof AppEvents>(event: K, data?: AppEvents[K]): boolean {
    return super.emit(event, data);
  }

  on<K extends keyof AppEvents>(
    event: K,
    listener: (data?: AppEvents[K]) => void
  ): this {
    return super.on(event, listener);
  }
}

const NetworkEvent = new TypedEventEmitter();

export default NetworkEvent;
