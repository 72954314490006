class UnitConverter {
  /*
   * Conversion factors to meters.
   * The value of each unit is multiplied by the factor to get the value in meters.
   */
  private static distanceConversionFactors = {
    meters: 1,
    kilometers: 1000,
    miles: 1621.371,
    yards: 0.9144,
    feet: 0.3048,
    inches: 0.0254,
    centimeters: 0.01,
    millimeters: 0.001,
    nauticalMiles: 1852,
  };

  /*
   * Conversion factors for size units.
   * The value of each unit is multiplied by the factor to get the value in bytes.
   */
  private static binarySizeConversionFactors = {
    bits: 1 / 8, // 1 byte = 8 bits
    bytes: 1,
    kilobits: 1024 / 8, // 1 kilobit = 1024 bits
    kilobytes: 1024,
    megabits: (1024 * 1024) / 8, // 1 megabit = 1024 kilobits
    megabytes: 1024 * 1024,
    gigabits: (1024 * 1024 * 1024) / 8, // 1 gigabit = 1024 megabits
    gigabytes: 1024 * 1024 * 1024,
    terabits: (1024 * 1024 * 1024 * 1024) / 8, // 1 terabit = 1024 gigabits
    terabytes: 1024 * 1024 * 1024 * 1024,
  };

  static convertDistance(
    value: number | string,
    fromUnit: keyof typeof UnitConverter.distanceConversionFactors,
    toUnit: keyof typeof UnitConverter.distanceConversionFactors,
    fixedCount = 0
  ): number {
    if (!(fromUnit in this.distanceConversionFactors)) {
      throw new Error(`Unknown unit: ${fromUnit}`);
    }

    if (!(toUnit in this.distanceConversionFactors)) {
      throw new Error(`Unknown unit: ${toUnit}`);
    }

    const valueInMeters =
      Number(value || 0) * this.distanceConversionFactors[fromUnit];
    const result = valueInMeters / this.distanceConversionFactors[toUnit];

    if (fixedCount !== undefined) {
      return parseFloat(result.toFixed(fixedCount));
    }

    return result;
  }

  static convertBinarySize(
    value: number | string,
    fromUnit: keyof typeof UnitConverter.binarySizeConversionFactors,
    toUnit: keyof typeof UnitConverter.binarySizeConversionFactors,
    fixedCount?: number
  ): number {
    if (!(fromUnit in this.binarySizeConversionFactors)) {
      throw new Error(`Unknown size unit: ${fromUnit}`);
    }

    if (!(toUnit in this.binarySizeConversionFactors)) {
      throw new Error(`Unknown size unit: ${toUnit}`);
    }

    const valueInBytes =
      Number(value || 0) * this.binarySizeConversionFactors[fromUnit];

    const result = valueInBytes / this.binarySizeConversionFactors[toUnit];

    if (fixedCount !== undefined) {
      return parseFloat(result.toFixed(fixedCount));
    }

    return result;
  }
}

export default UnitConverter;
